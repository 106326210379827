import {acceptHMRUpdate, defineStore} from 'pinia'
import api from '~/api'
import type {LoginParams, RegisterParams} from '~/api/modules/auth'
import {User} from '~/types/user.type'

export const useAuthStore = defineStore('auth', () => {
  const authenticated = ref(false)
  const checkedAuthenticate = ref(false)
  const user: Ref<User> = ref(new User())
  const selectedDiningParticipantId: Ref<number> = ref(user.value.customer?.diningParticipants[0].id || 0)

  const isAuthenticated = computed(() => {
    return authenticated.value
  })

  function setSelectedDiningParticipantId(id: number) {
    selectedDiningParticipantId.value = id
  }

  async function getMe() {
    checkedAuthenticate.value = true
    const res = await api.users.getUser()
    user.value = res.data
    authenticated.value = true

    if (selectedDiningParticipantId.value === 0 && user.value.customer?.diningParticipants.length > 0)
      setSelectedDiningParticipantId(user.value.customer.diningParticipants[0].id)

    return res
  }

  async function forgotPassword(email: string) {
    await api.auth.getCookie()
    return api.auth.forgotPassword(email)
  }

  async function resetPassword(params: RegisterParams, token: string) {
    await api.auth.getCookie()
    return api.auth.resetPassword(params, token)
  }
  async function sendVerificationMail() {
    return api.auth.sendVerificationMail()
  }
  async function login(config: LoginParams) {
    await api.auth.getCookie()
    return api.auth.login(config)
      .then(async (response) => {
        authenticated.value = true
        return response
      })
      .catch((error) => {
        throw error
      })
  }

  async function register(config: RegisterParams) {
    await api.auth.getCookie()
    return api.auth.register(config)
      .then(response => response)
      .catch((error) => {
        throw error
      })
  }

  async function logout() {
    return api.auth.logout()
      .then(() => {
        authenticated.value = false
        user.value = new User()
        selectedDiningParticipantId.value = 0
      })
      .catch((error) => {
        throw error
      })
  }

  return {
    getMe,
    login,
    register,
    logout,
    isAuthenticated,
    user,
    sendVerificationMail,
    forgotPassword,
    resetPassword,
    checkedAuthenticate,
    selectedDiningParticipantId,
    setSelectedDiningParticipantId,
  }
})
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
