import axiosInstance from '~/api/axios'
import type {Institution} from '~/types/institution.type'
import type {DiningParticipantType} from '~/types/diner.type'

// TODO maybe use useAxios instead
class InstitutionsModule {
  getInstitutions(code: string) {
    return axiosInstance.get<Institution>('/institution/', {params: {code}})
  }

  fetchDiningParticipantTypes(institutionId: number) {
    return axiosInstance.get<DiningParticipantType[]>(`/institution/${institutionId}/dining-participant-types`)
  }
}

const module = new InstitutionsModule()
export default module
