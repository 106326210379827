import {format} from 'date-fns'
import axiosInstance from '../axios'
import {ApiModule} from '../ApiModule'
import type {Menu, MenuLine, PermanentMenuLine} from '~/types/menuLine.type'

class MenuLineModule extends ApiModule {
  async getMenuLines(diningParticipantId: number, startDate: Date, endDate: Date) {
    this.cancelPreviousRequest(diningParticipantId.toString())
    return axiosInstance.get<MenuLine[]>(`/dining-participant/${diningParticipantId}/menu-lines/`, {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}, signal: this.getAbortController(diningParticipantId.toString())?.signal})
  }

  fetchPermanentMenuLines(diningParticipantId: number) {
    this.cancelPreviousRequest(diningParticipantId.toString())
    return axiosInstance.get<PermanentMenuLine[]>(`/dining-participant/${diningParticipantId}/menu-lines/permanent`, {signal: this.getAbortController(diningParticipantId.toString())?.signal})
  }

  fetchInstitutionMenuLines(institutionId: number | undefined, startDate: Date, endDate: Date) {
    return axiosInstance.get<MenuLine[]>(`/institution/${institutionId}/menu-lines`, {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}})
  }

  addMenuToCustomMenuLine(menu: Menu, supplyTypeId: number, institutionId: number) {
    return axiosInstance.post<Menu>('/custom-menu-lines/', {menuId: menu.id, supplyTypeId, institutionId})
  }

  fetchMenuLines(customerId: number, startDate: Date, endDate: Date) {
    this.cancelPreviousRequest(customerId.toString())

    return axiosInstance.get(`customer/${customerId}/menu-lines`, {params: {startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd')}, signal: this.getAbortController(customerId.toString())?.signal})
  }

  fetchCustomMenuLines(supplyTypeId: number, institutionId: number) {
    return axiosInstance.get('custom-menu-lines', {params: {supplyTypeId, institutionId}})
  }
}

const module = new MenuLineModule()
export default module
