import axiosInstance from '~/api/axios'
import type {Diner, Group} from '~/types/diner.type'

export interface DinerGroup {
  diningParticipants: DinerGroupPair[]
}
interface DinerGroupPair {
  diningParticipantId: number
  groups: Group[]
}

class GroupModule {
  fetchGroups(customerId: number) {
    return axiosInstance.get<DinerGroup>(`/customer/${customerId}/groups`)
  }

  fetchInstitutionGroups(institutionId: number) {
    return axiosInstance.get<Group[]>(`/institution/${institutionId}/groups`)
  }

  editGroupForDiners(institutionId: number, params: EditGroupParams) {
    return axiosInstance.put<Diner[]>(`/institution/${institutionId}/groups`, {...params})
  }
}

export interface EditGroupParams {
  groupId: number
  diningParticipantIds: number[]

}

const module = new GroupModule()
export default module
