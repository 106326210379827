import {acceptHMRUpdate, defineStore} from 'pinia'
import api from '~/api'
import type {NotificationParams} from '~/api/modules/customerNotifications'
import type {CustomerNotification} from '~/types/notification.type'

export const useCustomerNotificationStore = defineStore('customerNotification', () => {
  const notifications: Ref<Array<CustomerNotification>> = ref([])

  async function getCustomerNotifications(customerId: number) {
    try {
      const res = await api.customerNotifications.getCustomerNotifications(customerId)
      notifications.value = res.data
    }
    catch (e) {
    }
  }

  async function markNotificationAsRead(customerId: number, notificationId: number, params: NotificationParams) {
    try {
      await api.customerNotifications.markNotificationAsRead(customerId, notificationId, params)
      notifications.value = notifications.value.map((notification) => {
        if (notification.id === notificationId)
          notification.pivot.hasSeen = true

        return notification
      })
    }
    catch (e) {
    }
  }

  return {
    getCustomerNotifications,
    markNotificationAsRead,
    notifications,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useCustomerNotificationStore, import.meta.hot))
