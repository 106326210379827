import axiosInstance from '../axios'
import type {CustomerNotification} from '~/types/notification.type'

class CustomerNotificationsModule {
  async getCustomerNotifications(customerId: number) {
    return axiosInstance.get<CustomerNotification[]>(`/customer/${customerId}/notifications`)
  }

  async markNotificationAsRead(customerId: number, notificationId: number, params: NotificationParams) {
    return axiosInstance.put(`/customer/${customerId}/notifications/${notificationId}`, {...params})
  }
}

export interface NotificationParams {
  hasSeen: boolean
}

const module = new CustomerNotificationsModule()
export default module
