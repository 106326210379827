import type {AxiosResponse} from 'axios'
import {ApiModule} from '../ApiModule'
import axiosInstance from '~/api/axios'
import type {Customer, Mandate} from '~/types/customer.type'
import type {Diner, DinerPayload} from '~/types/diner.type'
import type {Inquiry, InquiryCategory} from '~/types/inquiry.type'

export interface CustomerListParams {
  id?: number
}

export interface CustomerParams {
  id?: number
  city: string
}

export interface CustomerDiningParticipantParams {
  customerId: number
  diner: Partial<DinerPayload>
}

// TODO maybe use useAxios instead
class CustomersModule extends ApiModule {
  getCustomer(params: CustomerParams) {
    const id = params.id
    delete params.id
    const data = {
      params,
    }
    return axiosInstance.get<Customer>(`/customer/${id}/`, data)
  }

  createCustomer(params: Customer) {
    return axiosInstance.post<Customer>('/customer/', params)
  }

  updateCustomer(customerId: number, params: Partial<Customer>) {
    return axiosInstance.put<Customer>(`/customer/${customerId}`, {...params})
  }

  getCustomerDiningParticipants(params: CustomerParams) {
    const id = params.id
    delete params.id
    const data = {
      params,
    }
    return axiosInstance.get<Diner[]>(`/customer/${id}/dining-participants/`, data)
  }

  createCustomerDiningParticipant(params: CustomerDiningParticipantParams) {
    return axiosInstance.post<Diner>(`/customer/${params.customerId}/dining-participants/`, params.diner)
  }

  fetchInvoices(customerId: number) {
    return axiosInstance.get(`/customer/${customerId}/invoices`)
  }

  updateCustomerDiningParticipant(params: CustomerDiningParticipantParams) {
    return axiosInstance.put<Diner>(`/customer/${params.customerId}/dining-participants/${params.diner.id}`, params.diner)
  }

  fetchInquiryCategories(): Promise<AxiosResponse<InquiryCategory[]>> {
    return axiosInstance.get('/inquiry-categories')
  }

  sendInquiry(inquiry: Inquiry) {
    return axiosInstance.post('/inquiries', inquiry)
  }

  createMandate(customerId: number, mandate: Omit<Mandate, 'id'>): Promise<AxiosResponse<Mandate>> {
    return axiosInstance.post(`/customer/${customerId}/customer-mandates`, {...mandate})
  }

  deleteMandate(customerId: number, mandateId: number) {
    return axiosInstance.delete(`/customer/${customerId}/customer-mandates/${mandateId}`)
  }
}

const module = new CustomersModule()
export default module
