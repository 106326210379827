import {type UserModule} from '~/types'

export const install: UserModule = ({isClient, router}) => {
  if (isClient) {
    const authStore = useAuthStore()
    function getDashboard() {
      switch (authStore.user?.role) {
        case 'customer':
          return {name: 'dashboard'}
        case 'institution_admin':
          return {name: 'institution-dashboard'}
      }
    }
    router.beforeEach(async (to) => {
      try {
        await authStore.getMe()
      }
      catch (error) {

      }

      if (to.meta.guest && !to.meta.roles?.length)
        return authStore.isAuthenticated ? getDashboard() : true

      if (to.meta.guest && to.meta.roles?.length)
        return !authStore.isAuthenticated || to.meta.roles?.includes(authStore.user?.role)

      if (!to.meta.guest && authStore.isAuthenticated) {
        if (authStore.user?.emailVerifiedAt == null && to.name !== 'register-verify')
          return {name: 'register-verify'}
        else if (authStore.user?.emailVerifiedAt != null && authStore.user.customer == null && to.name !== 'register-details' && authStore.user?.role === 'customer')
          return ({name: 'register-details'})

        if (!to.meta.roles?.length) return true

        if (to.meta.roles?.includes(authStore.user?.role)) return true
        else
          return getDashboard()
      }

      return {name: 'login'}
    })
  }
}
