import type {Customer} from './customer.type'
import type {InstitutionAdmin} from '~/types/institutionAdmin.type'

export interface IUser {
  id: number
  email: string
  emailVerifiedAt: Date
  firstName: string
  lastName: string
  role: string
  customer?: Customer
  institutionAdmin?: InstitutionAdmin
}

export class User implements IUser {
  id!: number
  email!: string
  emailVerifiedAt!: Date
  firstName!: string
  lastName!: string
  role!: string
  customer!: Customer
  institutionAdmin!: InstitutionAdmin

  constructor(user: Partial<IUser> = {}) { Object.assign(this, user) }
}
